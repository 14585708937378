.errorPage {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  .errorPageInner {
    text-align: center;
    .errorImg {
      span {
        height: 100% !important;
        display: block !important;
      }
    }
    .title {
      margin-top: 3.5rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font: var(--headline-extra-bold-32-39);
      span {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.5rem;
      }
    }
    .description {
      color: var(--dark-opacity-50);
      max-width: 740px;
      margin: 1rem auto 3rem auto;
      display: block;
      font: var(--headline-medium-18-22);
    }
    .bottom {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
