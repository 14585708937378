#productDetail{
  .rollbackWrapper{
    font: var(--body-bold-14-17);
    margin-bottom: 2rem;
  }
  .tabContentWrapper{
    margin-top: 1.5rem;
  }
  .productInfoWrapper{
    + .productInfoWrapper{
      margin-top: 2rem;
    }
  }
  .descriptionCardWrapper{
    + .descriptionCardWrapper{
      margin-top: 1.5rem;
    }
  }
}