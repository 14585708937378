#cargoBoxesPage{
    .filterDropdown {
        .dropdownBody{
            form{
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    table{
        width: 100%;
    }
    .cargoBoxList{
        //margin-top: 1rem;
    }
}