#siteHeader{
    padding: 0 2.5rem;
    border-bottom: 1px solid var(--grey-10);
    height: 96px;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    .logo{
        img{
            display: block;
        }
    }

    #userDropdown {
      position: relative;
      z-index: 5;
      .userAvatar {
        background-color: var(--primary-opacity-5);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 48px;
        height: 48px;
        text-align: center;
        position: relative;
        font: var(--body-extra-bold-14-17);
        color: var(--primary);
      }
    }
}