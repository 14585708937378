#customersPage{
    .filterDropdown {
        .dropdownBody{
            form{
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    .customerList{
        //margin-top: 1rem;
        .status{
            >div{
                display: inline-block;
                vertical-align: bottom;
                margin-right: 0.5rem;
            }
        }
    }
}