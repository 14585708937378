#siteLayout{
    .innerContent{
        display: grid;
        grid-template-columns: 330px calc(100% - 330px);
        .layoutLeftSide{
            border-right: 1px solid var(--grey-10);
            padding: 2.5rem 1.5rem;
            min-height: calc(100dvh - 96px);
        }
        .layoutRightSide{
            padding: 1.5rem;
        }
    }
}