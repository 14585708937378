#orderDetailModal{

    [data-simplebar] {
      height: 100%;
      padding: 1.5rem;
      box-sizing: border-box;
    }
    .modalLoading{
      height: 307px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .orderSummary{
      .orderNumber{
        font: var(--headline-semi-bold-24-29);
        display: block;
        margin-bottom: 1.5rem;
      }
    }
    .customerDetail{
      margin-bottom: 1.5rem;
      .detailList{
        padding: 0;
        margin: 0;
        li{
          display: block;
          font: var(--body-medium-14-17);
          .value{
            font: var(--body-semi-bold-14-17);
          }
        }
      }
    }
    .productList{
      > label {
        + label{
          margin-top: 1rem;
        }
      }
      .productDetail{
          .detailList{
              padding: 0;
              margin: 0;
              display: grid;
              row-gap: 0.5rem;
              li{
                display: block;
                font: var(--body-medium-14-17);
                .value{
                  font: var(--body-semi-bold-14-17);
                }
              }
          }
          
      }
    }
}