.orderDetail{
  .customerSummary{
    margin-bottom: 2rem;
  }
  .addressSummary{
    margin-top: 2rem;
  }
  .itemSummary{
    margin-top: 2rem;
  }
}