#confirmationModal {
    .confirmationModalInner {
      //padding: 1.5rem 0;
      height: 100%;
      .title {
        display: block;
        margin-bottom: 1rem;
        font: var(--headline-bold-20-24);
      }
      .description {
        display: block;
        margin: 0;
        font: var(--title-medium-16-19);
        b {
          font: var(--title-extra-bold-16-19);
        }
      }
    }
  }
  