.fileActionCard{
    padding: 1.5rem;
    border:1px solid var(--grey-10);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    + .fileActionCard{
        margin-top: 1.5rem;
    }
    .leftFrame{
        .title{
            display: block;
            font: var(--headline-semi-bold-18-22);
        }
    }
    .rightFrame{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button{
            + button {
                margin-left: 1rem;
            }
        }
    }
}