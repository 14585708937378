.circleSummaryBox{
    border: 1px solid var(--dark-opacity-10);
    border-radius: 0.5rem;
    padding: 1.5rem;
    .summaryHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            font: var(--title-bold-16-19);
        }
        .rightContent{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .badge{
                margin-right: 0.5rem;
            }
        }
    }
    .dropdown{
        .dropdownToggleButton{
            box-sizing: border-box;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            transition: all .2s;
            outline: none;
            border:0;
            background: none;
            color: var(--primary);
        }
    }
}