:root {
  --fc-border-color: var(--grey-10);
}

.fullCalendar {
  position: relative;
  margin-top: 1rem;
  //height: calc(100dvh - 130px);
  //overflow: hidden;
  .loadingPage {
    position: absolute;
    background: white;
    z-index: 10;
    left: 0;
    bottom: 0;
    right: 0;
    top: 157px;
    height: auto;
    display: block;

    > div {
      margin: 2rem auto 0 auto;
    }
  }
  .fc-theme-standard td, .fc-theme-standard th{
    border: initial;
  }

  .fc {
    .fc-scrollgrid{
      border-right-width: 1px;
      border-bottom-width: 1px;
    }
    .fc-scrollgrid-section-sticky {
      > *{
        background: transparent;
      }
    }
    .fc-toolbar.fc-header-toolbar {
      border: 1px solid var(--grey-10);
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;
      margin-bottom: 2rem;
    }

    .fc-toolbar-title {
      font: var(--headline-semi-bold-20-24);
      display: block;
    }

    .fc-scrollgrid {
      border-radius: 0.5rem;
      position: relative;
      overflow: hidden;
    }

    .fc-col-header-cell {
      border: 1px solid var(--fc-border-color);
      border-left: 0;
      border-right: 0;
      .fc-col-header-cell-cushion {
        text-align: center;
        padding: 1rem 1.5rem;
        color: var(--dark);
        font: var(--body-semi-bold-14-17);
        display: block;
      }
    }
  }

  tr.fc-list-day.fc-day-today th, tr.fc-list-day.fc-day-today th div {
    background: #1890ff !important;
    color: white !important;
  }

  tr.fc-list-day.fc-day-today th div a {
    color: white !important;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(212 212 212 / 15%);
  }

  .fc .fc-daygrid-day.fc-day {
    border: 1px solid var(--fc-border-color);
  }

  .fc-daygrid-day-events {
    > div {
      + div {
        margin-top: 1rem !important;
      }
    }

    .fc-daygrid-day-bottom {
      display: none;
    }

    .fc-h-event {
      padding: 0.5rem;
      letter-spacing: 0;
      border-radius: 0.5rem;
      margin: 0 0.5rem !important;
      background-color: transparent;
      box-shadow: none;
      color: #000;
      margin-bottom: 1.5rem;
      border: 1px solid var(--grey-10);
      transition: all .1s ease;

      &:hover {
        border-color: var(--grey-10);
        box-shadow: 0 0 16px 0 var(--icon-shadow-color);
      }

      &:after {
        opacity: 0;
      }

      .fc-event-main {
        .eventDetail {
          color: var(--dark);

          .eventName {
            display: block;
            font: var(--body-semi-bold-14-17);
            color: var(--dark-opacity-50);
            margin-top: 0.5rem;
            white-space: break-spaces;
          }

          .eventType {
            display: block;
            padding: 0;
            font: var(--body-semi-bold-14-17);

            &:is(.warning) {
              > div {
                color: var(--red);
              }
            }

            > div {
              margin-right: 0.5rem;
              display: inline-block;
              vertical-align: middle;
              color: var(--green);
            }
          }

          .eventProductCount {
            font: var(--caption-medium-12-15);
            margin-top: 0.5rem;

            b {
              font: var(--caption-semi-bold-12-15);
            }
          }
        }
      }
    }
  }

  td.fc-day.fc-day-sat, td.fc-day.fc-day-sun {
    //background: rgb(247, 247, 245);
  }

  .fc {
    .fc-daygrid-day.fc-day {
      border-top-right-radius: 0 !important;
    }
    .fc-daygrid-day.fc-day-today {
      background-color: transparent;

      .fc-daygrid-day-number {
        height: 24px;
        width: 24px;
        border-radius: 0.25rem;
        text-align: center;
        color: white;
        background: var(--primary);
        padding: 0;
        line-height: 24px;
      }
    }

    .fc-daygrid-day-number {
      font: var(--caption-bold-12-15);
      color: var(--dark-opacity-50);
      margin: 0.5rem 0.5rem 0 0;
    }

    .fc-daygrid-day-top {
      margin-bottom: 0.5rem;
    }

    .fc-day-other {
      .fc-daygrid-day-top {
        opacity: 1;

        .fc-daygrid-day-number {
          color: var(--dark-opacity-25);
        }
      }
    }

    .fc-button-group {
      align-items: center;

      > .fc-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        color: var(--dark-opacity-75);
        background-color: white;
        font: var(--body-semi-bold-14-17);
        transition: all .2s ease;
        outline: none !important;
        margin-left: 1rem;
        text-transform: capitalize;

        &:is(.fc-today-button) {
          border: none !important;
          color: var(--primary);
          box-shadow: none !important;
          padding: 0;
          margin: 0;
          opacity: 1;

          &:hover, &:focus {
            color: var(--primary-hover);
          }
        }

        &:is(.fc-prev-button), &:is(.fc-next-button) {
          border: none !important;
          box-shadow: none !important;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 0.5rem;
          margin: 0;
          font-size: 10px;
          color: var(--dark-opacity-50);

          .fc-icon {
            font-weight: 700;
          }

          &:is(.fc-prev-button) {
            margin-right: 12px;
          }

          &:is(.fc-next-button) {
            margin-left: 12px;
          }

          &:hover, &:focus {
            box-shadow: 0px 0px 16px 0px var(--dark-opacity-10) !important;
          }
        }

        &:is(.fc-dayGridMonth-button), &:is(.fc-dayGridWeek-button) {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          border-radius: 0.5rem;
          height: 40px;
          padding: 0 0;
          width: 101px;
          border: 1px solid transparent;
          color: var(--dark-opacity-75);
          background-color: white;
          font: var(--body-semi-bold-14-17);

          &:hover, &:focus, &:is(.fc-button-active) {
            background-color: white;
            border: 1px solid var(--grey-10);
            box-shadow: 0px 6px 30px 0px var(--box-shadow-color);
            color: var(--dark);
          }
        }
      }
    }
  }
}