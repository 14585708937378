#paymentHistoryModal {
    @media screen and (min-height: 750px) {
      @media screen and (min-width: 576px) {
        max-height: 686px;
      }
    }
  
    [data-simplebar] {
      height: 100%;
      padding: 1.5rem;
      box-sizing: border-box;
      .simplebar-track {
        //right: -23px;
      }
    }
    .list {
      padding: 0;
      margin: 0;
      display: grid;
      row-gap: 1.5rem;
      .listItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--dark);
        height: 17px;
        &:is(.disabled) {
          color: var(--grey-30);
        }
        .date {
          font: var(--body-regular-14-17);
        }
        .priceWrapper {
          .price {
            font: var(--body-bold-14-17);
            margin-left: 12px;
          }
        }
      }
    }
  }
  