.goToUp {
  position: fixed;
  right: 24px;
  bottom: 32px;
  line-height: 40px;
  text-align: center;
  z-index: 2050;
  border: 1px solid var(--dark-opacity-5);
  font-size: 13px;
  font-weight: 700;
  color: var(--primary);
  background: white;
  box-shadow: 0 6px 30px var(--box-shadow-color);
  border-radius: 8px;
  padding: 0 15px;
  transition: all 0.2s ease;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  &:is(.active) {
    opacity: 1;
    visibility: visible;
  }
  /*@include media-breakpoint-down(lg) {
    bottom: 110px;
  }*/
  span {
    display: inline-block;
    &:is(.icon) {
      width: 16px;
      height: 16px;
      //background-image: url('/icon-long-arrow-up.svg');
      vertical-align: middle;
      background-position: center center;
      background-size: contain;
      margin-right: 8px;
      transition: all 0.2s ease;
      animation: scrollUp 1s infinite;
      > div {
        display: block;
      }
      /*
      @include media-breakpoint-down(lg) {
        margin: 0;
      }
      */
    }
    &:is(.text) {
      /*
      @include media-breakpoint-down(lg) {
        display: none;
      }
      */
    }
  }
  &:hover {
    color: var(--primary-hover);
    box-shadow: 0 6px 30px var(--box-shadow-color) !important;
    span {
      &:is(.icon) {
        //background-image: url('/icon-long-arrow-up-hover.svg');
      }
    }
  }
  &:focus {
    box-shadow: 0 6px 30px var(--box-shadow-color) !important;
  }
  &:active {
    box-shadow: 0 6px 30px var(--box-shadow-color) !important;
  }
}

@keyframes scrollUp {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-2px);
  }
}
