#loginPage{
    height: 100dvh;
    display: grid;
    grid-template-columns: 618px 1fr;
    .leftContent{
        height: inherit;
        background-color: var(--primary);
        color: white;
        padding: 6rem;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        .logo{
            position: absolute;
            left: 6rem;
            top: 6rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img{
                + img{
                    margin-left: 14px;
                }
            }
        }
        .textContent{
            .title{
                font: var(--headline-bold-40-48);
                margin-top: 0;
                margin-bottom: 3.5rem;
            }
            .description{
                font: var(--headline-semi-bold-18-22);
                margin: 0;
                max-width: 309px;
            }
        }
    }
    .rightContent{
        height: inherit;
        padding: 7rem;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        .formContent{
            .title{
                font: var(--headline-extra-bold-24-29);
                margin-top: 0;
                margin-bottom: 3.5rem;
                display: block;
            }
            .formWrapper{
                min-width: 370px;
                form{
                    .input-container{
                        &:first-child{
                            margin-bottom: 1.5rem;
                        }
                    }
                    /*.input-container{
                        + .input-container{
                            margin-top: 2rem;
                        }
                    }*/
                    .rememberMe{
                        display: inline-block;
                        margin-top: 1rem;
                        margin-bottom: 1.5rem;
                    }
                    button{
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
}