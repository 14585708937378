#orderScoreModal{
    [data-simplebar] {
      height: 100%;
      padding: 1.5rem;
      box-sizing: border-box;
    }
    .modalInnerContent{
        border:1px solid var(--dark-opacity-10);
        border-radius: 0.5rem;
        padding: 1rem;
        .customerDetail{
          margin-bottom: 1.5rem;
          .title{
            font: var(--title-bold-16-19);
            margin-bottom: 1rem;
            display: block;
          }
          .detailList{
            padding: 0;
            margin: 0;
            li{
              display: block;
              font: var(--body-regular-14-17);
              .value{
                font: var(--body-semi-bold-14-17);
                .address{
                  font: var(--body-regular-14-17);
                }
                .country{
                  display: block;
                }
              }
            }
          }
        }
        form{
            display: grid;
            row-gap: 1.5rem;
            .scoreList{
              display: grid;
              row-gap: 1.5rem;
              user-select: initial;
              pointer-events: initial;
              margin-top: 0.5rem;
            }
            .productList{
              display: grid;
              row-gap: 1rem;
            }
            .textLinkWrapper{
              font: var(--body-bold-14-17);
            }
        }
    }
}