.listingWrapper{
  .listTitle{
    display: block;
    font: var(--headline-semi-bold-20-24);
    margin-bottom: 1.5rem;
  }
  .listing{
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 0;
    overflow: auto;
    position: relative;
    .rc-table{
      &:is(.hasLoading){
        tr{
          td {
            &:first-child{
              padding: 0 1rem 0 1.5rem !important;
            }
            padding: 0 1rem !important;
          }
        }
      }
    }
    table{
      width: 100%;
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
    tr{
      &:hover{
        .actions{
          opacity: 1;
        }
      }
      .rc-table-cell-fix-right{
        padding-right: 0;
        right: 0 !important;
        padding-left: 0;
      }
      td{
        padding: 0 !important;
        &:first-child{
          a{
            padding: 0 1rem 0 1.5rem !important;
          }
        }
        a{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 100%;
          padding: 0 1rem;
        }
      }
    }
    .actions{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: white;
      padding: 6px 1.5rem 6px 10px;
      transition: all .1s;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      &:after{
        content: "";
        position: absolute;
        right: 100%;
        background: linear-gradient(270deg, #fff, hsla(0, 0%, 100%, 0));
        top: 0;
        bottom: 0;
        width: 150px;
        z-index: -1;
        user-select: none;
        pointer-events: none;
      }
      button{
        + button{
          margin-left: 0.5rem;
        }
      }
    }
  }
  .paginationWrapper{
    margin-top: 1.5rem;
    //height: 2.5rem;
  }
}
