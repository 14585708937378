#createManualReturnCodeModal{
    @media screen and (min-height: 750px) {
      @media screen and (min-width: 576px) {
        max-height: 472px;
        height: 100vh;
      }
    }
  
    [data-simplebar] {
      height: 100%;
      padding: 1.5rem;
      box-sizing: border-box;
    }
    form{
        .shipmentDetail{
            display: grid;
            row-gap: 1.5rem;
        }
    }
}