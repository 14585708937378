.filterDropdown{
  margin-bottom: 1rem;
  &:is(.show){
    margin-bottom: 0.5rem;
    .dropdownHeader{
      .dropdownToggle{
        border-radius: 0.5rem 0.5rem 0 0;
        border-color: var(--primary);
        background-color: white;
        &:before{
          border-width: 2px;
        }
        .rightSide{
          .dropdownIcon{
            transform: rotate(180deg);
          }
        }
      }
    }
    .dropdownBody{
      display: block;
      margin-top: 0.25rem;
      box-shadow: 0px 6px 30px 0px var(--box-shadow-color);
    }
  }
  &:is(.filled){
    .dropdownHeader{
      .dropdownToggle{
        border-color: var(--primary);
        background-color: white;
        &:before{
          border-width: 2px;
        }
        *,.title {
          color: var(--primary);
        }
      }
    }
  }
  .dropdownHeader{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    .dropdownToggle{
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;
      background-color: var(--dark-opacity-3);
      display: flex;
      align-content: center;
      justify-content: space-between;
      cursor: pointer;
      color: var(--dark-opacity-50);
      position: relative;
      border-color: var(--dark-opacity-10);
      &:before{
        content: "";
        position: absolute;
        inset: 0;
        border: 1px solid var(--dark-opacity-10);
        border-color: inherit;
        border-radius: inherit;
        user-select: none;
        pointer-events: none;
      }
      &:hover{
        border-color: var(--primary);
        background-color: white;
      }
      .leftSide{
        .title, .icon{
          display: inline-block;
          vertical-align: middle;
        }
        .title{
          font: var(--title-bold-16-19);
          margin-left: 1rem;
        }
        .filterCount{
          font: var(--caption-extra-bold-12-15);
          background-color: var(--primary);
          color: white;
          margin-left: 0.5rem;
          display: inline-block;
          width: 1rem;
          height: 1rem;
          line-height: 1rem;
          text-align: center;
          border-radius: 50%;
        }
      }
      .rightSide{
        .dropdownIcon{
          display: inline-block;
          vertical-align: middle;
          transition: all .2s ease;
          *{
            color: var(--dark);
          }
        }
        .clearButton{
          display: inline-block;
          vertical-align: middle;
          font: var(--body-bold-13-16);
          margin-right: 1rem;
        }
      }
    }
    .exportWrapper{
      margin-left: 1rem;
    }
  }
  .dropdownBody{
    display: none;
    border: 1px solid var(--dark-opacity-10);
    padding: 1.5rem;
    border-radius: 0 0 0.5rem 0.5rem;
    form{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
    }
    .dropdownFooter{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1.5rem;
      button{
        + button{
          margin-left: 1rem;
        }
      }
    }
  }
}