@import '../../node_modules/krl-alfred/dist/style/style.min.css';

// components
@import './components/layout/header';
@import './components/layout/index';
@import './components/layout/navigation';
@import './components/layout/gotoup';

@import './components/summary/circleSummaryBox';

@import './components/filterDropdown';
@import './components/listing/listing';

@import './components/pages/orderCalendar/calendar';
@import './components/cards/fileActionCard';
@import './components/errors/errorPage';

@import './modals/returnShipmentWithItemModal';
@import './modals/updateOrderModal';
@import './modals/confirmationModal';
@import './modals/paymentHistoryModal';
@import './modals/updateOrderProductModal';
@import './modals/orderScoreModal';
@import './modals/sendShipmentModal';
@import './modals/createManualReturnCodeModal';
@import './modals/salesOfferModal';
@import './modals/orderDetailModal';
@import './modals/boxDetailModal';
@import './modals/addProductToBoxModal';
@import './modals/updateProductInfoModal';
@import './modals/updateProductDescriptionModal';

@import './pages/home';
@import './pages/login';
@import './pages/orders';
@import './pages/orderDetail';
@import './pages/customers';
@import './pages/orderCalendar';
@import './pages/cargoBoxes';
@import './pages/cargoBoxDetail';
@import './pages/addProduct';
@import './pages/productDetail';
@import './pages/products';

:root{
  --orange:#fd7e14;
  --warning : #ffc107;
  --danger: #dc3545;
  --success: #198754;
  --cyan: #0dcaf0;
}
html{
  scroll-behavior:smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:is(.locked, [class*='modalActive_'], .hasToast) {
    overflow: hidden !important;
  }
}
.imgFluid{
  width: 64px;
  height: 64px;
  border-radius: 0.5rem;
  border: 1px solid var(--dark-opacity-5);
  padding: 0.5rem;
  margin: 1rem 0;
  box-sizing: border-box;
  display: block;
}
.inputNoResult{
  height: 51px;
  line-height: 51px;
}
.loadingPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 145px);
}
.toastCustomContent{
  margin-top: 1rem;
}
.toastMessageFont {
  margin-top: 1rem;
}
.pageTitle{
  display: block;
  margin-bottom: 1.5rem;
  margin-top: 0;
  font: var(--headline-semi-bold-20-24);
}
.pageDesc{
  font: var(--title-medium-16-19);
  display: block;
  color: var(--dark-opacity-50);
  margin: 0;
}
a{
  text-decoration: none;
  color: inherit;
}
.d-none{
  display: none;
}
.Logo {
  background-image: url("../images/logo.svg");
  background-repeat: no-repeat;
  width: 8rem;
  height: 2.4rem;
  background-size: contain;
  background-position: center;
}
.ant-layout{
  background: white;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.ant-layout-sider {
  padding-top: 20px;
  padding-bottom: 20px;
  .Logo {
    margin: 0 auto 20px auto;
  }
}

.ant-layout-content {
  padding: 24px 0;
  margin: 0;
  min-height: calc(100vh - 98px);
  width: 100%;
}

.order-detail-description-card {
  .ant-descriptions-item-content {
      max-width: 500px;
  }
}

.filters {
  .ant-picker {
    width: 100%;
  }
}

.orders__table {
  .ant-table-cell-fix-right {
    width: 24px;
  }
}
.ant-card-extra {
  padding: 16px 0;
}
.ant-table-content{
  width: 100%; 
  position: relative;
  overflow-x: scroll;
  table{
    width: 100%;
    .ant-table-tbody > tr > td{
      white-space: nowrap;
    }
    .ant-table-thead > tr > th{
      white-space: nowrap;
    }
  }
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{

}
.ant-menu{
  height: calc(100% - 39px);
  overflow: auto;
  position: relative;
}
.hidden{
  display: none;
}
.leftNavbar{
  -webkit-flex: 0 0 290px !important;
  -moz-box-flex: 0;
  flex: 0 0 290px !important;
  position: relative;
  width: 290px !important;
  max-width: 290px !important;
}
.rightLayout{
  padding: 0;
  -webkit-flex: 1 0;
  -moz-box-flex: 1;
  flex: 1 0;
  max-width: 100%;
  .navbar{
    padding: 10px 24px;
    background-color: rgb(237 237 237 / 85%);
  }
  .innerContent{
    padding: 10px 24px;
    @media screen and (min-width: 1150px) {
      min-width: 1100px;
    }
    @media screen and (max-width: 1150px) {
      .ant-space.ant-space-vertical{
        width: 100%;
      }
    }
  }
  .footer{
    padding: 20px 24px;
  }
}
.openMenu {
  .leftNavbar{
    display: none;
  }
  .rightLayout{
    margin: 0;
  }
}

.orderDetail{
  .orderDetailButtons{
    .orderDetailButtonsInner{

    }
  }
  .orderItemCardInner{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    column-gap: 16px;
  }
}


@media screen and (max-width: 1150px) {
  .orderDetail{
    .orderDetailButtons{
      .ant-descriptions-header{
        display: block;
        .ant-descriptions-title{
          margin-bottom: 1rem;
        }
      }
      .orderDetailButtonsInner{
        display: flex;
        flex-direction: column;
        .ant-space-item{
          display: block;
          width: 100%;
          button{
            width: 100%;
          }
        }
      }
    }
    .orderItemCard{
      .orderItemCardInner{
        display: block;
      }
      > .ant-card-body{
        padding: 0;
      }
    }
    .itemActions{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      > * {
        margin-bottom: 0 !important;
      }
      .dates{
        width: 100%;
        grid-area: 1 / 1 / 2 / 3;
      }

      @media screen and (max-width: 1150px) {
        grid-template-columns: 1fr;
        .dates{
          grid-template-columns: 1fr;
          grid-area: 1 / 1;
          + .dates{
            grid-area: 2 / 1;
          }
        }
      }
    }
  }
  .layout{
    &:is(:not(.openMenu)){
      .collapseWrapper{
        .cargoBtn{
          display: none;
        }
      }
    }
  }
}

/* btn */
.menuButton {
	position: relative;
	display:  inline-block;
	width: 30px;
	height: 30px;
	margin: 0;
  padding: 0;
  background-color: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  &:after{
    display: none;
  }
  span {
    margin: 0 auto;
    position: relative;
    top: 0;
    -webkit-transition-duration: 0s; transition-duration: 0s;
    -webkit-transition-delay: 0.2s; transition-delay: 0.2s;
    &:before , &:after{
      position: absolute;
      content: '';
    }
    &:before {
      margin-top: -6px;
      -webkit-transition-property: margin, -webkit-transform; transition-property: margin, transform;
      -webkit-transition-duration: 0.2s; transition-duration: 0.2s;
      -webkit-transition-delay: 0.2s, 0s; transition-delay: 0.2s, 0s;
    }
    &:after {
      margin-top: 6px;
    }
  }
  span, span:before, span:after {
    width: 30px;
    height: 3px;
    background-color: #000;
    display: block;
  }
}   
.ant-btn-success{
  color: #fff;
  border-color: var(--success);
  background: var(--success);
  &:hover,&:focus,&:active{
    border-color: var(--success);
    background: white;
    color: var(--success);
  }
}
.ant-btn-orange{
  color: #fff;
  border-color: var(--orange);
  background: var(--orange);
  &:hover,&:focus,&:active{
    border-color: var(--orange);
    background: white;
    color: var(--orange);
  }
}
.ant-btn-warning{
  color: #fff;
  border-color: var(--warning);
  background: var(--warning);
  &:hover,&:focus,&:active{
    border-color: var(--warning);
    background: white;
    color: var(--warning);
  }
}
.ant-steps-item-icon , .ant-steps-item-title{
  cursor: pointer;
}
.formBuilder{
  min-height: 600px;
}


.ant-collapse {
  border:0;
  .ant-collapse-item {
    border-radius: 0 !important;
    position: relative;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    margin: 0 0 1rem 0;
    > .ant-collapse-header {
      background: #1890ff;
      color: #fff;
      border-radius: 0 !important;
    }

    .ant-collapse-header-collapsible-only {
      .ant-collapse-header-text {

      }
    }

    &.ant-collapse-no-arrow {
      > .ant-collapse-header {

      }
    }
  }
}

.printibleContent{
  table{
    width: 100%;
    margin-bottom: 4rem;
    border: 2px solid #dedede;
    th{
      font-size: 30px;
      padding: 10px 10px 5px 10px;
    }
    td{
      padding: 10px 20px;
      font-size: 40px;
      line-height: 1.2;
      word-spacing: 6px;
      &:first-child{
        width: 250px;
      }
    }
    tbody{
      tr:last-child{
        td{
          padding-bottom: 20px;
        }
      }
    }
  }
  .customer{
  }
  .sender{

  }
}
.collapseWrapper{
  position: relative;
  .cargoBtn{
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 5;
    background-color: white;
  }
}
.cargoCode{
  border: 2px dashed black;
  text-align: center;
  padding: 40px 20px;
  border-radius: 20px;
  font-size: 30px;
  font-weight: bold;
}

.itemActions{
  display: flex;
  flex-wrap:nowrap;
  width: 100%;
  column-gap: 20px;
  .dates{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 20px;
    row-gap: 10px;
    flex: 0 0 auto;
    width: 30%;
    > *{
      button {
        width: 100%;
      }
      @media screen and (min-width: 1150px) {
        &:nth-child(3){
          grid-column: 1/3;
        }
      }
    }
    &:is(.updateDate){
      @media screen and (max-width: 1150px) {
        grid-template-columns: auto;
        > *{
          &:nth-child(1){
            grid-column: 1/2;
          }
          &:nth-child(2){
            grid-column: 2/3;
          }
          &:nth-child(3){
            grid-column: 1/3;
          }
        }
      }
    }
  }
  .ant-picker , .ant-select{
    width: 100% !important;
  }
  > *{
    flex: 1 0;
  }
}
.ant-card-extra{
  width: 100%;
}
.button-success{
  color:black;
  background-color:var(--green) !important;
  border-color:var(--green) !important;
  &:hover{
    opacity: .7;
  }
}

.primaryTable{
  table{
    background-color: var(--cyan);
    color: white;
    tr{
      td{
        background-color: var(--cyan) !important;
        color: white !important;
      }
    }
  }
}
.ant-input-number{
  width: 100%;
}
#paymentHistoryModal{
  .list {
    padding: 0;
    margin: 0;
    display: grid;
    row-gap: 1.5rem;
    .listItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--dark);
      height: 17px;
      &:is(.disabled) {
        color: var(--grey-30);
      }
      .date {
        font: var(--body-regular-14-17);
      }
      .priceWrapper {
        .price {
          font: var(--body-bold-14-17);
          margin-left: 12px;
        }
      }
    }
  }
}

.isLoading{
  position: absolute;
  inset: 0;
  z-index: 99;
  background: rgb(255 255 255 / 37%);
  display: flex;
  align-items: center;
  justify-content: center;
  &:is(.hidden){
    display: none;
  }
}
#orderDetailModal{
  .title{
    font-weight: bold;
    font-size: 40px;
  }
  .orderSummary{
    display: block;
    padding: 0;
    margin: 0 0 20px 0;
    li{
      display: block;
      padding: 0;
      margin: 0;
      strong{
        display: inline-block;
        vertical-align: middle;
        min-width: 150px;
        color: rgba(55, 53, 47, 0.65);
        font-weight: 400;
      }
      span{
        display: inline-block;
        vertical-align: middle;
        color: rgb(55, 53, 47);
      }
    }
  }
  .ant-table-content{
    overflow: hidden;
  }
  .ant-btn{
    width: 100%;
    margin-top: 20px;
  }
}