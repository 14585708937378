#salesOfferModal{
    @media screen and (min-height: 750px) {
      @media screen and (min-width: 576px) {
        height: 406px;
      }
    }
  
    [data-simplebar] {
      height: 100%;
      padding: 1.5rem;
      box-sizing: border-box;
    }
    .productDetail{
        .detailList{
            padding: 0;
            margin: 0;
            display: grid;
            row-gap: 0.5rem;
            li{
              display: block;
              font: var(--body-medium-14-17);
              .value{
                font: var(--body-semi-bold-14-17);
              }
            }
        }
    }
}