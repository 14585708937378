#updateProductInfoModal{
  [data-simplebar] {
    height: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
  }
  form{
    display: grid;
    row-gap: 1.5rem;
  }
}